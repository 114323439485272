import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { UserRestService } from './rest/user/user-rest.service'

declare var gtag: Function

@Injectable({
	providedIn: 'root',
})
export class GoogleAnalyticsService {
	constructor(private userRest: UserRestService) {}
	user
	gtmLoaded: boolean = false
	public initialize() {
		// dynamically add analytics scripts to document head
		setTimeout(() => {
			try {
				const url = 'https://www.googletagmanager.com/gtag/js?id='
				const gTagScript = document.createElement('script')
				gTagScript.async = true
				gTagScript.src = `${url}${environment.googleAnalyticsId}`
				document.head.appendChild(gTagScript)

				const dataLayerScript = document.createElement('script')
				dataLayerScript.innerHTML = `
			window.dataLayer = window.dataLayer || [];
			function gtag(){dataLayer.push(arguments);}
			gtag('js', new Date());
			gtag('config', '${environment.googleAnalyticsId}');`
				document.head.appendChild(dataLayerScript)
				this.user = this.userRest.getCurrentUserValue()
				if (this.user) {
					gtag('set', { user_id: this.user._id })
				}

				this.gtmLoaded = true
			} catch (e) {
				console.error('Error adding Google Analytics', e)
			}
		}, 0)
	}

	// use gtag.js to send Google Analytics Events
	public event(
		action: string,
		eventCategory?: string,
		eventLabel?: string,
		value?: string
	) {
		this.user = this.userRest.getCurrentUserValue()
		if (
			this.gtmLoaded &&
			environment.production &&
			(!this.user || (this.user && !this.user.isAdmin))
		) {
			gtag('event', action, {
				...(this.user?._id && {
					event_user: this.user?._id,
					user_id: this.user?._id,
					userId: this.user?._id,
				}),
				...(eventCategory && { event_category: eventCategory }),
				...(eventLabel && { event_label: eventLabel }),
				...(value && { value: value }),
			})
		}
	}
}
