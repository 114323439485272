import { Group } from './group.model'
import { Visit } from './visit.model'

export class User {
	public id: string
	public _id: string

	// Login User
	public firstName: string
	public lastName: string
	public email: string

	// User Personal Information
	public gender: string
	public birthDate: string
	public relationshipStatus: string
	public profilePicture: string
	public wizard: any
	public folder: any
	public recommendations: any
	public age: any
	public hasPayoutAccount: boolean
	public hasSubmittedId: boolean

	public catcherPreferences: object

	// User Profile
	public address: string
	public roomNb: string
	public isHosted: boolean
	public contractDuration: number
	public noEndContractDuration: boolean
	public vicinity: string
	public lng: number
	public lat: number
	public phone: string
	public budget: number
	public description: string
	public income: number
	public supIncome: number
	public badges: { string: boolean }

	// User Settings
	public acceptSms: boolean
	public acceptMail: boolean
	public sharePhone: boolean
	public paidReservation: boolean
	public forceStartDate: boolean
	public reservationFees: number

	// User Situations
	public situations: any[]

	// Facebook
	public facebook: any

	// User Group
	public group: any
	public hasActiveGroup: number

	// User Premium
	public isPremium: boolean
	public isPremiumCanceled: boolean
	public premiumType: string
	public premiumExpire: Date
	public previousPremiums: any[]
	public hasSubscribtionPremium: boolean

	// Ohter
	public isAdmin: boolean
	public isSubAdmin: boolean
	public isActive: boolean
	public isBan: boolean
	public isSearching: boolean
	public findWith: string
	public lastIp: string
	public lastSms: Date
	public lastConnection: Date
	public updatedAt: string
	public views: number
	public isOnline: boolean
	public emailChecked: boolean
	public guarantors: any
	public isCertif: boolean
	public askCertif: boolean
	public hasPhone: boolean
	public stripeClientId: string
	public stripeIncomeId: string
	public status: string
	private _json: any

	public isCatcher: boolean
	public isAppointed: boolean
	public hasGuarantorVisa: boolean
	public visaleAmount: number

	public appointments: any[]

	public lookingForFlat: boolean
	public lookingForColoc: boolean

	public searchDate: any
	public searchRange: number

	public searchHasFurniture: string

	public convs: string[]

	// public appointmentDate: Date
	// public appointmentStatus: string
	// public appointmentAdmin: string
	// public previousAppointments: any[]

	public seenPhoneNumber: string[]

	public visits: any[]

	public toJSON() {
		return this._json
	}

	public constructor(user, deep = true) {
		const {
			_id = null,
			firstName = null,
			lastName = null,
			email = null,
			gender = null,
			birthDate = null,
			relationshipStatus = null,
			profilePicture = null,
			wizardBuffer = null,
			folder = null,
			recommendations = null,
			address = null,
			roomNb = null,
			contractDuration = null,
			noEndContractDuration = null,
			location = null,
			phone = null,
			budget = null,
			description = null,
			income = null,
			supIncome = null,
			badges = null,
			acceptSms = null,
			acceptMail = null,
			sharePhone = null,
			situations = null,
			facebook = null,
			isAdmin = null,
			isSubAdmin = null,
			isActive = null,
			isBan = null,
			isPremium = null,
			isPremiumCanceled = null,
			premiumType = null,
			premiumExpire = null,
			previousPremiums = null,
			hasSubscribtionPremium = null, // >:(
			isSearching = null,
			findWith = null,
			lastIp = null,
			lastSms = null,
			lastConnection = null,
			updatedAt = null,
			views = null,
			group = null,
			hasActiveGroup = null,
			isOnline = null,
			age = null,
			emailChecked = null,
			guarantors = null,
			isCertif = null,
			askCertif = null,
			hasPhone = null,
			hasPayoutAccount = null,
			hasSubmittedId = null,
			stripeClientId = null,
			stripeIncomeId = null,
			status = null,
			isCatcher = null,
			isAppointed = null,
			appointments = null,
			isHosted = false,
			lookingForFlat = null,
			lookingForColoc = null,

			searchDate = null,
			searchRange = null,
			searchHasFurniture = null,
			// appointmentDate = null,
			// appointmentStatus = null,
			// appointmentAdmin = null,
			// previousAppointments = null
			seenPhoneNumber = null,
			visits = null,
			hasGuarantorVisa = null,

			catcherPreferences = null,
			convs = null,
			visaleAmount = null,
			paidReservation = null,
			forceStartDate = null,
			reservationFees = null,
		} = user

		this._json = user

		this.id = _id
		this._id = _id
		this.firstName = firstName
		this.lastName = lastName
		this.email = email
		this.gender = gender
		this.birthDate = birthDate ? birthDate.split('T')[0] : null
		this.relationshipStatus = relationshipStatus
		this.profilePicture = profilePicture
		this.wizard = wizardBuffer && { ...wizardBuffer }
		this.folder = folder && { ...folder }
		this.recommendations = recommendations && { ...recommendations }
		this.address = address
		this.roomNb = roomNb
		this.contractDuration = contractDuration
		this.noEndContractDuration = noEndContractDuration
		this.lng = location && location.coordinates[0]
		this.lat = location && location.coordinates[1]
		this.vicinity = location && location.vicinity
		this.isHosted = isHosted
		this.phone = phone
		this.budget = budget
		this.description = description
		this.income = income
		this.supIncome = supIncome
		this.badges = badges && { ...badges }
		this.acceptSms = acceptSms
		this.acceptMail = acceptMail
		this.sharePhone = sharePhone
		this.situations = situations && [...situations]
		this.facebook = facebook && { ...facebook }
		this.isAdmin = isAdmin
		this.isSubAdmin = isSubAdmin
		this.isActive = isActive
		this.paidReservation = paidReservation
		this.forceStartDate = forceStartDate
		this.reservationFees = reservationFees
		this.isBan = isBan
		this.isPremium = isPremium
		this.isPremiumCanceled = isPremiumCanceled
		this.premiumType = premiumType
		this.premiumExpire = premiumExpire
		this.previousPremiums = previousPremiums && [...previousPremiums]
		this.hasSubscribtionPremium = hasSubscribtionPremium
		this.isSearching = isSearching
		this.findWith = findWith
		this.lastIp = lastIp
		this.lastSms = lastSms
		this.lastConnection = lastConnection
		this.updatedAt = updatedAt
		this.views = views
		this.isOnline = isOnline
		this.age = age
		this.emailChecked = emailChecked
		this.guarantors = guarantors && { ...guarantors }
		this.hasActiveGroup = hasActiveGroup
		this.isCertif = isCertif
		this.askCertif = askCertif
		this.hasPhone = hasPhone
		this.hasPayoutAccount = hasPayoutAccount
		this.hasSubmittedId = hasSubmittedId
		this.stripeClientId = stripeClientId
		this.stripeIncomeId = stripeIncomeId
		this.status = status
		this.isCatcher = isCatcher
		this.isAppointed = isAppointed
		this.appointments = appointments && [...appointments]

		this.lookingForFlat = lookingForFlat
		this.lookingForColoc = lookingForColoc

		this.searchRange = searchRange
		this.searchDate = searchDate
		this.searchHasFurniture = searchHasFurniture
		this.hasGuarantorVisa = hasGuarantorVisa
		this.visaleAmount = visaleAmount

		// this.appointmentDate = appointmentDate
		// this.appointmentStatus = appointmentStatus
		// this.appointmentAdmin = appointmentAdmin
		// this.previousAppointments = previousAppointments && [...previousAppointments]
		this.seenPhoneNumber = seenPhoneNumber && [...seenPhoneNumber]

		this.catcherPreferences = catcherPreferences && {
			...catcherPreferences,
		}
		this.convs = convs

		if (deep) {
			this.visits = visits?.length
				? visits.map((visit) => new Visit(visit, false))
				: []

			this.group = group && {
				candidate: group?.candidate.length
					? group?.candidate.map((g) => new Group(g, false))
					: [],
				created: group?.created.length
					? group?.created.map((g) => new Group(g, false))
					: [],
				joined: group?.joined.length
					? group?.joined.map((g) => new Group(g, false))
					: [],
				favorites: group?.favorites.length
					? group?.favorites.map((g) => new Group(g, false))
					: [],
			}
		} else {
			this.group = group && { ...group }
			this.visits = visits && [...visits]
		}
		/*if (group && !this.hasActiveGroup) {
		  const arr = group?.created
		  arr.forEach((g) => {
			//  console.log('in')
			if (g.isActive) {
			  // console.log('If')
			  this.hasActiveGroup++
			}
		  })
		  //console.log('hasActivatedGroup :', this.hasActiveGroup)
		}*/
	}
}

export const MockUser = {
	id: 'testId',
	// this.group : {
	// 	"candidate" : [],
	// 	"joined" : [],
	// 	"created" : [],
	// 	"favorites" : []
	// },
	// "badges" : {
	// 	"isSmoker" : false,
	// 	"isCook" : false,
	// 	"isMusician" : false,
	// 	"isSpendthrift" : false,
	// 	"isPartyGoer" : false,
	// 	"isLayabout" : false,
	// 	"isGeek" : false,
	// 	"isTraveller" : false,
	// 	"isGenerous" : false,
	// 	"isAnimalFriend" : false,
	// 	"isMessy" : false,
	// 	"isManiac" : false
	// },
	// "recommendations" : {
	// 	"toMe" : [],
	// 	"to" : []
	// },
	// "guarantors" : {
	// 	"toMe" : [],
	// 	"to" : []
	// },
	// "location" : {
	// 	"type" : "Point",
	// 	"coordinates" : [
	// 		2.3522219,
	// 		48.856614
	// 	],
	// 	"postalCode" : 75004
	// },
	// "folder" : {
	// 	"document" : {
	// 		"IDC" : "/public/folders/6299f136d2b2de88d1775753/idcUser16542572061366299f136d2b2de88d1775753.jpg",
	// 		"payslips" : null,
	// 		"addressProof" : null,
	// 		"studentCard" : null,
	// 		"bilan" : null,
	// 		"KBis" : null,
	// 		"taxNotice" : null
	// 	},
	// 	"askAccess" : {
	// 		"them" : [],
	// 		"me" : []
	// 	},
	// 	"givenAccess" : {
	// 		"them" : [],
	// 		"me" : []
	// 	},
	// 	"valid" : {
	// 		"IDC" : "no",
	// 		"payslips" : "no",
	// 		"addressProof" : "no",
	// 		"studentCard" : "no",
	// 		"bilan" : "no",
	// 		"KBis" : "no",
	// 		"taxNotice" : "no",
	// 		"lease" : "no",
	// 		"workContract" : "no"
	// 	},
	// 	"askValid" : false,
	// 	"isValid" : false,
	// 	"docusign" : {
	// 		"attestations" : [],
	// 		"other" : [],
	// 		"searchWarrants" : []
	// 	},
	// 	"envelopes" : [],
	// 	"rental" : {
	// 		"client" : {
	// 			"IDC" : [],
	// 			"KBis" : [],
	// 			"addressProof" : [],
	// 			"bilan" : [],
	// 			"lease" : [],
	// 			"payslip" : [],
	// 			"receipt" : [],
	// 			"studentCard" : [],
	// 			"taxNotice" : [],
	// 			"workContract" : []
	// 		},
	// 		"guarantors" : []
	// 	},
	// 	"signatures" : []
	// },
	// "facebook" : {
	// 	"friends" : [],
	// 	"id" : null
	// },
	// "wizardBuffer" : {
	// 	"budget" : 800,
	// 	"validOnly" : true,
	// 	"role" : "member",
	// 	"photos" : [],
	// 	"rooms" : [],
	// 	"candidate" : null,
	// 	"hasFlat" : false
	// },
	firstName: 'lolo',
	lastName: 'dédé',
	email: 'laurentd@netc.fr',
	isOnline: true,
	gender: 'male',
	description: "Salut salut c'est lolo le bargo",
	budget: 600,
	views: 6,
	income: 2000,
	supIncome: 150,
	premiumType: 'test',
	acceptSms: true,
	acceptMail: true,
	hasPhone: true,
	hasActiveGroup: 0,
	isActive: true,
	isSearching: false,
	isBan: false,
	isPremium: false,
	emailChecked: true,
	isAdmin: false,
	profilePicture:
		'public/profiles/6299f136d2b2de88d1775753/profilePictureUser16547793582036299f136d2b2de88d1775753.png',
	askCertif: false,
	isCertif: false,
	address: 'Paris, France',
	stripeClientId: null,
	stripeIncomeId: null,
	hasPayoutAccount: false,
	hasSubmittedId: false,
	situations: [
		// {
		// 	"places" : [
		// 		ObjectId("6299f57ed42d909a95e18ae5")
		// 	],
		// 	"end8Month" : null,
		// 	"activity2Years" : null,
		// 	"_id" : ObjectId("6299f57ed42d909a95e18ae4"),
		// 	"status" : "interim"
		// },
		// {
		// 	"places" : [],
		// 	"end8Month" : null,
		// 	"activity2Years" : "-",
		// 	"_id" : ObjectId("6299f585d42d909a95e18ae7"),
		// 	"status" : "freelancer"
		// }
	],
	phone: '+33768963250',
	age: 42,
	birthDate: '1980-09-16T00:00:00.000Z',
	relationshipStatus: 'inRelationship',
	appointments: [],
	findWith: '',
	hasSubscribtionPremium: false,
	isSubAdmin: false,
	lookingForColoc: true,
	lookingForFlat: true,
	noEndContractDuration: false,
	premiumExpire: null,
	previousPremiums: [],
	roomNb: '3',
	searchDate: {
		asap: true,
		noEndData: true,
	},
	searchHasFurniture: 'any',
	searchRange: 5000,
	seenPhoneNumber: [],
	sharePhone: false,
	status: 'member',
	visits: [],
	lastSms: null,
	isAppointed: false,
}
