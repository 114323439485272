import { isPlatformBrowser } from '@angular/common'
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core'
import { Subscription, Subject, BehaviorSubject } from 'rxjs'

import { environment } from '../../../environments/environment'
import { AuthRestService } from '../rest/auth/auth-rest.service'
import { UserRestService } from '../rest/user/user-rest.service'
import { DynamicScriptLoaderServiceService } from '../dynamic-script-loader-service.service'
import { HttpClient } from '@angular/common/http'

declare let FB: any

/**
 * We can use https://developers.facebook.com/tools/explorer/ for testing Facebook api.
 * If we want to fetch the data of user in Facebook,
 * we call the fonction FB.login with the limited scopes et get the id and the accesstoken of Facebook firstly
 * and then with those, we send a request to the back.
 * In the back, we use the module `request` pour send a request to Facebook(voir la fonction getFacebookData dans dataService.service.ts).
 * Cliquez ici pour accéder l'organigramme : https://drive.google.com/open?id=1dTPzD9qnoxEG0rJe_wAkRoLgE6IgYq2x
 */

//IMPORTANT !!!!! You have to run facebook connect via dynamic script loader before using this service
@Injectable({
	providedIn: 'root',
})
export class FacebookService implements OnDestroy {
	private isBrowser: boolean
	private subscription: Subscription
	private currentUser: any
	/*
	private eventCallback = new Subject<boolean>() // Source
	eventCallback$ = this.eventCallback.asObservable() // Stream
  */
	private initStatus = new BehaviorSubject(true)
	stream = this.initStatus.asObservable()

	constructor(
		private userRest: UserRestService,
		private authRest: AuthRestService,
		private dynScriptLoader: DynamicScriptLoaderServiceService,
		private httpClient: HttpClient,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		this.isBrowser = isPlatformBrowser(this.platformId)
		if (this.isBrowser) {
			this.subscription = this.userRest.currentUser.subscribe(
				(currentUser) => (this.currentUser = currentUser)
			)
			;(window as any).fbAsyncInit = async () => {
				// @ts-ignore
				FB.init({
					appId: environment.facebookId,
					cookie: true,
					xfbml: true,
					version: 'v8.0',
				})
				// @ts-ignore
				FB.AppEvents.logPageView()
				this.initStatus.next(false)
			}
		}
	}

	initFB() {
		this.dynScriptLoader.loadScript('FacebookConnect')
	}

	ngOnDestroy() {
		if (this.subscription) this.subscription.unsubscribe()
	}

	setAuthTokens(token, expiresIn) {
		this.authRest.setAuthTokens(token, expiresIn)
	}

	getUser(token) {
		return this.authRest.getUser(token)
	}

	public login(cb) {
		FB.login(
			(response: any) => {
				if (response.authResponse) {
					const { userID: id, accessToken } = response.authResponse
					cb(id, accessToken)
				} else {
					this.initStatus.next(false)
				}
			},
			{ scope: 'email,public_profile' }
		)
	}

	private status(cb) {
		if (this.currentUser.facebook.id !== 'Unknown') {
			this.login(cb)
		} else {
			FB.getLoginStatus((response: any) => {
				if (response.status === 'connected') {
					cb(response.authResponse.accessToken)
				} else {
					this.login(cb)
				}
			})
		}
	}

	private openFriendsList(accessToken, cb) {
		// console.log(accessToken)
		// @ts-ignore
		FB.ui(
			{ method: 'apprequests', message: 'Rejoins moi sur Appartoo' },
			(response1: any) => {
				// tslint:disable-next-line: forin
				for (const f in response1.to) {
					FB.api(
						`/${response1.to[f]}`,
						{
							access_token: accessToken,
							fields: ['last_name', 'first_name'].join(','),
						},
						'GET',
						(response2: any) => {
							if (response2.id) {
								const data = {
									firstName: response2.first_name,
									lastName: response2.last_name,
									fbId: response2.id,
								}
								cb(data)
							}
						}
					)
				}
			}
		)
	}

	public signIn() {
		return new Promise((resolve, reject) => {
			this.login(async (id, accessToken) => {
				try {
					const tokenData = await this.authRest
						.signinfb({ id, accessToken })
						.toPromise()
					resolve(tokenData)
				} catch (error) {
					reject(error)
				}
			})
		})
	}

	public signOut() {
		FB.logout((response: any) => {})
	}

	public sendJoinRequest(cb) {
		this.status((id, accessToken) => this.openFriendsList(accessToken, cb))
	}

	public signInFacebookApp() {
		FB.getLoginStatus(() => {
			window.location.href = `https://www.facebook.com/v2.10/dialog/oauth?
				client_id=${environment.facebookId}
				&redirect_uri=https://${
					environment.production ? 'www.' : 'jeanmichel.'
				}appartoo.com/
				&display=popup
				&response_type=token&scope=public_profile,email`
		})
	}

	public getFacebookUserData(access_token) {
		const fields = ['id', 'email', 'first_name', 'last_name'].join(',')
		return this.httpClient.get(
			`https://graph.facebook.com/me?access_token=${access_token}&fields=${fields}`
		)
	}
}
